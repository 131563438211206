import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { transition: 'slide' }
  },
  {
    path: '/studio',
    name: 'Studio',
    meta: { transition: 'slide' },
    component: () => import(/* webpackChunkName: "about" */ '../views/StudioView.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    meta: { transition: 'slide' },
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsView.vue')
  },
  {
    path: '/projects/:url',
    name: 'Projects',
    meta: { transition: 'slide' },
    component: () => import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Home' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition || to === from) {
      return ({ top: 0 })
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ top: 0 })
        }, 350)
      })
    }
  }
})

export default router
