<template>
  <main>
    <HomeFeature />
    <HomeContent v-if="loadComp" />
  </main>
</template>

<script>
import { onMounted, ref } from 'vue'
import HomeFeature from '@/components/home/HomeFeature.vue'
import HomeContent from '@/components/home/HomeContent.vue'
export default {
  name: 'HomeView',
  components: {
    HomeFeature,
    HomeContent
  },
  setup () {
    const loadComp = ref(false)
    onMounted(() => {
      setTimeout(() => {
        loadComp.value = true
      }, 600)
    })
    return {
      loadComp
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  @include mini {
    padding-bottom: toRem(124);
  }
  min-height: 200vh;
  padding-bottom: toRem(160);
  @include tablet {
    padding-bottom: toRem(224);
  }
  @include laptop {
    padding-bottom: toRem(288);
  }
}
</style>
