<template>
  <footer v-if="!overlay" class="noselect">
    <nav id="footer">
      <div class="socials-container anim delay-1">
        <p><a class="link" :class="screen === 'laptop' ? 'eff-base eff-2 eff-b' : null" href="https://www.instagram.com/bytriuna" target="_blank" rel="noopener">Instagram</a></p>
      </div>
      <div class="socials-container-2 anim delay-1">
        <p><a class="link ml" :class="screen === 'laptop' ? 'eff-base eff-2 eff-b' : null" href="https://www.behance.net/triuna/" target="_blank" rel="noopener">Behance</a></p>
      </div>
      <div class="privacy-container anim delay-2">
        <router-link class="link eff-base eff-2 eff-b eff-active" to="/terms">Terms & Privacy</router-link>
      </div>
      <div class="contact-container anim delay-3">
        <p><a class="link" :class="screen === 'laptop' ? 'eff-base eff-2 eff-b' : null" :href="prefix+decoded">{{ decoded }}</a></p>
      </div>
    </nav>
    <div class="hover-container anim">
      <p class="bold">{{ hover }}</p>
    </div>
  </footer>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const overlay = computed(() => store.getters.overlay)
    const screen = computed(() => store.getters.screen)

    const hover = computed(() => store.getters.hover)

    const decoded = window.atob('aGVsbG9AdHJpdW5hLnNp')
    const prefix = window.atob('bWFpbHRvOg==')

    return {
      hover,
      decoded,
      prefix,
      overlay,
      screen
    }
  }
}
</script>

<style lang="scss" scoped>
// footer {
//   mix-blend-mode: difference;
//   color: $color-bg;
// }
#footer {
  // @include mini {
  //   flex-direction: column;
  //   row-gap: toRem(2);
  //   padding-bottom: toRem(8);
  // }
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  z-index: 95;
  // pointer-events: none;
  padding-bottom: toRem(24);
  // margin-bottom: toRem(19);
  padding-left: 4.87vw;
  padding-right: 4.87vw;
  align-items: center;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  // mix-blend-mode: difference;
  // background: $color-bg;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // color: $color-font;
  .privacy-container {
    display: none;
  }
  .socials-container {
    .link {
      padding-right: toRem(8);
      padding-bottom: toRem(8);
      &:nth-of-type(2), &:nth-of-type(3) {
        padding-left: toRem(8);
      }
      @include mini {
        padding: 0;
        &:nth-of-type(2), &:nth-of-type(3) {
          padding: 0;
        }
      }
    }
  }
  .contact-container, .contact-container-2 {
    .link {
      padding-bottom: toRem(8);
    }
  }
  @include laptop {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: toRem(24);
    @include grid;
    .socials-container {
      grid-column: 5 / span 1;
      .link {
        padding: 0 !important;
      }
    }
    .socials-container-2 {
      grid-column: 6 / span 1;
      .link {
        padding: 0 !important;
      }
    }
    .privacy-container {
      display: inline;
      grid-column: 9 / span 2;
    }
    .contact-container {
      text-align: right;
      grid-column: 12 / span 2;
      .link {
        padding: 0;
      }
    }
  }
  // .anim {
  //   animation: reveal 0.65s ease forwards;
  //   transform: translateY(200%);
  //   animation-delay: 1s;
  // }
  // .delay-1 {
  //   animation-delay: 1.25s;
  // }
  // .delay-2 {
  //   animation-delay: 1.5s;
  // }
  // .delay-3 {
  //   animation-delay: 1.75s;
  // }
}
.hover-container {
  display: none;
  @include laptop {
    display: inline;
    position: fixed;
    bottom: toRem(24);
    left: 2.1875vw;
  }
}
@keyframes reveal {
  100% {
    transform: translateY(0);
  }
}
</style>
