<template>
  <section>
    <router-link
      class="project-container router-link" v-for="project in projects" :key="project.id" :class="project.feature.class"
      :to="{ name: 'Projects', params: { url: project.url }}"
      @mouseleave="hover(null)"
      @mouseover="hover(project.meta)"
    >
      <div v-if="project.feature.vimeo" :class="project.feature.ratio" class="video-container">
        <iframe class="iframe" :src="`https://player.vimeo.com/video/${project.feature.vimeoId}?h=${project.feature.vimeoH}&autoplay=1&loop=1&background=1`" width="100%" frameborder="0" allow="fullscreen"></iframe>
      </div>
      <picture v-else class="img-container">
        <source :srcset="require(`../../assets/images/${project.name}/${project.name}-${project.feature.url}.webp`)" type="image/webp">
        <source :srcset="require(`../../assets/images/${project.name}/${project.name}-${project.feature.url}.${project.feature.type}`)" :type="`image/${project.feature.mime}`">
        <img :src="require(`../../assets/images/${project.name}/${project.name}-${project.feature.url}.webp`)" :alt="project.feature.alt">
      </picture>
    </router-link>
  </section>
</template>

<script>
import { computed, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  setup () {
    const store = useStore()

    const projects = computed(() => store.getters.projects)

    function hover (name) {
      store.dispatch('hover', name)
    }

    onMounted(() => {
      store.dispatch('hover', null)

      setTimeout(() => {
        const scrollItems = gsap.utils.toArray('.anim-item')

        scrollItems.forEach(item => {
          gsap.from(item, {
            opacity: 0,
            y: 50,
            duration: 0.75,
            ease: 'Power3.ease',
            scrollTrigger: { trigger: item }
          })
        })
      }, 100)
    })
    onBeforeUnmount(() => {
      ScrollTrigger.getAll().forEach(t => {
        t.kill()
      })
      // gsap.globalTimeline.clear()
    })

    return {
      projects,
      hover
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  @include mini {
    row-gap: toRem(18);
    padding-top: 0;
  }
  padding-top: toRem(24);
  @include tablet {
    padding-top: toRem(32);
  }
  @include grid;
  row-gap: max(1.25vw, toRem(24));
  @include laptop {
    padding-top: toRem(80);
  }
  .project-container {
    grid-column: 2 / span 4;
    height: fit-content;
    position: relative;
    overflow: hidden;
    .uncover {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      .uncover_slice {
        margin-left: auto;
        margin-bottom: auto;
        height: 100%;
        width: 100%;
        background: $color-bg;
      }
    }
    .img-container {
      img {
        transition: transform .75s ease;
      }
      &:hover {
        img {
          transform: scale(1.075);
        }
      }
    }
  }
  video {
    width: 100%;
  }
  .video-container {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    z-index: -10;
    &::before {
      display: block;
      content: "";
    }
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  .r-vertical {
    &::before {
      padding-top: 140%;
    }
  }
  .r-horizontal {
    &::before {
      padding-top: 71.43%;
    }
  }
  .r-wide {
    &::before {
      padding-top: 66.66%;
    }
  }
  @include laptop {
    .img-h-right {
      grid-column: 6 / span 8;
    }
    .img-v-left {
      grid-column: 2 / span 4;
    }
    .img-h-center-s {
      grid-column: 6 / span 4;
    }
    .img-h-left {
      grid-column: 2 / span 8;
    }
    .img-h-right-s {
      grid-column: 10 / span 4;
    }
    .img-v-right {
      grid-column: 10 / span 4;
    }
  }
}
.anim-first {
  opacity: 0;
  animation: first 2s ease forwards;
}
@keyframes first {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
iframe {
  z-index: -10;
}
</style>
