<template>
  <NavHeader />
  <router-view v-slot="slotProps">
    <PageTransitions :route="slotProps.route">
      <component :is="slotProps.Component" :key="$route.fullPath" />
    </PageTransitions>
  </router-view>
  <CookieNotice
    v-if="isOpen"
    :agree="agree"
    msg="yes"
  />
  <NavFooter />
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import NavHeader from '@/components/navigation/NavHeader.vue'
import PageTransitions from '@/components/ui/PageTransitions.vue'
import CookieNotice from '@/components/ui/CookieNotice.vue'
import NavFooter from '@/components/navigation/NavFooter.vue'
export default {
  components: {
    NavHeader,
    CookieNotice,
    NavFooter,
    PageTransitions
  },
  setup () {
    const store = useStore()

    const tablet = '(min-width: 766px)'
    const laptop = '(min-width: 1028px)'

    const mqs = [
      window.matchMedia(tablet),
      window.matchMedia(laptop)
    ]

    if (matchMedia) {
      mqs.forEach((item) => {
        item.addListener(screenChange)
        screenChange(item)
      })
    }

    function screenChange () {
      if (mqs[1].matches) {
        store.dispatch('setScreen', 'laptop')
      } else if (mqs[0].matches) {
        store.dispatch('setScreen', 'tablet')
      } else {
        store.dispatch('setScreen', 'mobile')
      }
    }

    const isOpen = ref(false)
    const getGDPR = localStorage.getItem('GDPR:accepted')

    function checkGDPR () {
      if (getGDPR === 'true') {
        isOpen.value = false
      } else {
        isOpen.value = true
      }
    }
    checkGDPR()

    function agree () {
      isOpen.value = false
      localStorage.setItem('GDPR:accepted', true)
    }

    return {
      agree,
      isOpen
    }
  }
}
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body, #app {
  height: 100%;
}
html {
  background-color: $color-bg;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}
body {
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior-y: none;
  @include tablet {
    min-height: calc(100vh - calc(100vh - 100%));
  }
}
body.overlay-open {
  overflow: hidden;
}
@import "@/styles/_fonts.scss";
@import "@/styles/_globals.scss";
</style>
