<template>
  <transition :name="route.meta.transition || 'slide'" mode="out-in" appear>
    <slot></slot>
  </transition>
</template>

<script>
export default {
  props: {
    route: {
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
$transition-duration: 0.35s;
// .slide-enter-active,
.slide-leave-active {
  transition: all $transition-duration ease;
}
// .slide-enter-from {
//   opacity: 0;
// }
// .slide-enter-to {
//   opacity: 1;
// }
.slide-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.slide-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}
</style>
