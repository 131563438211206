<template>
  <div>
    <p>We use cookies to ensure you get the best experience on the website.</p>
    <p>You can read more on our <router-link class="link eff-2 eff-w" :class="screen === 'laptop' ? 'eff-base-r eff-active' : 'eff-base eff-full'" to="/terms">Terms & Privacy</router-link> page.</p>
    <button @click="click()">Agreed</button>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  props: {
    agree: Function
  },
  setup (props) {
    const store = useStore()
    const screen = computed(() => store.getters.screen)

    function click () {
      props.agree()
    }
    return {
      click,
      screen
    }
  }

}
</script>

<style lang="scss" scoped>
div {
  @include mini {
    font-size: toRem(14);
    line-height: toRem(18);
    padding: toRem(16) toRem(8);
  }
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: toRem(26);
  width: 90.26%;
  // height: 18vh;
  // min-height: toRem(124);
  padding: toRem(16) 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: $color-bg;
  background: $color-font;
  @include laptop {
    width: 40vw;
    min-width: toRem(600);
    height: 18vh;
    min-height: toRem(124);
  }
  p {
    text-align: center;
  }
  button {
    @include mini {
      border-radius: toRem(12);
      margin-top: toRem(12);
      width: toRem(96);
      height: toRem(24);
    }
    color: $color-font;
    background: $color-bg;
    border-radius: toRem(18);
    margin-top: toRem(18);
    width: toRem(126);
    height: toRem(36);
    align-self: center;
    &:hover {
      // color: $color-bg;
      // background: $color-font;
      text-decoration: underline;
      text-decoration-thickness: toRem(1);
    }
  }
}

</style>
