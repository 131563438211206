<template>
  <header class="noselect">
    <nav id="header" :class="{ 'overlay' : overlay }">
      <div class="logo-container" ref="logocontainer">
        <router-link to="/" @click="overlayClose">
          <svg id="logo-triuna" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.18 67.1">
            <defs>
              <rect id="clip-rect" width="100.18" height="27" />
              <rect id="clip-rect-btm" width="100.18" height="29.1" y="38" />
              <clipPath id="clip-mask">
                <use href="#clip-rect"/>
              </clipPath>
              <clipPath id="clip-mask-btm">
                <use href="#clip-rect-btm" />
              </clipPath>
            </defs>
            <g id="logo-group" class="scroll-color" :fill="overlay ? '#FAFAFA' : '#1A1A1A'">
              <g clip-path="url(#clip-mask)">
                <g visibility="hidden">
                  <path id="letter-t" class="letters-top" d="M14.65,5.62V26.28H7.81V5.62H0V0H22.47V5.62Z" />
                  <path id="letter-r" class="letters-top" d="M43.2,26.28H36.36V0H45c6.22,0,10.54,3.28,10.54,8.68a7.62,7.62,0,0,1-4.14,7l7,10.58H50.54L44.6,17.14H43.2Zm0-14.51H45c2.27,0,3.64-1.22,3.64-3.09S47.27,5.54,45,5.54H43.2Z"/>
                  <path id="letter-i" class="letters-top" d="M78.8,0V26.28H72V0Z"/>
                  <path id="letter-d" class="letters-top" d="M100.18,13.14a3.63,3.63,0,0,1-3.67,3.64h-.07a3.64,3.64,0,1,1,0-7.28h.07A3.63,3.63,0,0,1,100.18,13.14Z"/>
                  <animateTransform
                    attributeName="transform" attributeType="XML" type="translate" from="0 28" to="0 0" begin="0.5s" dur="0.7s"
                    calcMode="spline" keyTimes="0;1" keySplines="0 0 0 1"
                  ></animateTransform>
                  <set attributeName="visibility" from="hidden" to="visible" begin="0.5s"/>
                </g>
              </g>
              <g clip-path="url(#clip-mask-btm)">
                <g visibility="hidden">
                  <path id="letter-u" class="letters-btm" d="M11.05,60.18c2.56,0,4.22-1.65,4.22-5V39.48h6.84v15.7c0,7-4.79,11-11.06,11s-11-4-11-11V39.48H6.84V55.22C6.84,58.53,8.5,60.18,11.05,60.18Z"/>
                  <path id="letter-n" class="letters-btm" d="M54,39.7H60.7V67.1L43,52.3V66H36.36V38.58L54,53.42Z"/>
                  <path id="letter-a" class="letters-btm" d="M100,66.56H92.78L91,62.88H80.58L78.7,66.56H71.5L85.76,39.12ZM83.17,57.74h5.18l-2.59-5.12Z"/>
                  <animateTransform
                      attributeName="transform" attributeType="XML" type="translate" from="0 56" to="0 0" begin="0.5s" dur="1s"
                      calcMode="spline" keyTimes="0;1" keySplines="0 0 0 1"
                  ></animateTransform>
                  <set attributeName="visibility" from="hidden" to="visible" begin="0.5s"/>
                </g>
              </g>
            </g>
          </svg>
        </router-link>
      </div>
      <div class="menu-1-container">
        <p>
          <router-link to="/" class="link eff-base eff-2 eff-b eff-active">Work</router-link>
        </p>
      </div>
      <div class="menu-2-container">
        <p>
          <router-link :to="{ name: 'Studio' }" class="link eff-base eff-2 eff-b eff-active">Studio</router-link>
        </p>
      </div>
      <div class="menu-3-container">
        <p>
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 6 9"><circle cx="3" cy="6" r="3" fill="#000" opacity="0.8" style="mix-blend-mode:difference;isolation:isolate"/></svg>
            <span class="s-block"><a class="link eff-base eff-2 eff-b" :href="prefix+decoded">Start project</a></span>
          </span>
        </p>
      </div>
      <div class="menu-mobile">
        <p @click="overlayToggle" class="menu-button" :class="{ 'light' : overlay }">{{ menu }}</p>
      </div>
      <transition name="overlay" mode="out-in">
        <div v-if="overlay" class="header-mid light">
          <ul class="mid-container">
            <li class="pb"><router-link to="/" class="mid h1 link eff-active-mobile" @click="overlayClose">Work</router-link></li>
            <li class="pb"><router-link :to="{ name: 'Studio' }" class="mid h1 link eff-active-mobile" @click="overlayClose">Studio</router-link></li>
            <li class="pb"><a class="mid h1 link" :href="prefix+decoded" @click="overlayClose">Start project</a></li>
          </ul>
        </div>
      </transition>
      <transition name="overlay" mode="out-in">
        <div v-if="overlay" class="header-footer light">
          <div class="item-left">
            <router-link to="/terms" class="link eff-active-mobile" @click="overlayClose">Privacy & Terms</router-link>
          </div>
          <div class="item-right">
            <a class="link eff-base" :href="prefix+decoded" @click="overlayClose">{{ decoded }}</a>
          </div>
        </div>
      </transition>
    </nav>
  </header>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    const menu = computed(() => store.getters.menu)
    const overlay = computed(() => store.getters.overlay)

    const decoded = window.atob('aGVsbG9AdHJpdW5hLnNp')
    const prefix = window.atob('bWFpbHRvOg==')

    const overlayToggle = () => {
      if (overlay.value === false) {
        store.dispatch('overlay', true)
      } else if (overlay.value === true) {
        store.dispatch('overlay', false)
      }
    }

    const overlayClose = () => {
      store.dispatch('overlay', false)
    }

    return {
      decoded,
      prefix,
      menu,
      overlay,
      overlayToggle,
      overlayClose
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 95;
  padding-top: toRem(20);
  // background: $color-bg;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // color: $color-font;
  // box-decoration-break: clone;
  // -webkit-box-decoration-break: clone;
  @include grid;
  @include laptop {
    padding-top: toRem(21);
    // mix-blend-mode: difference;
    // color: #FAFAFA;
  }
  .logo-container {
    grid-column: 2 / span 2;
    // pointer-events: all;
    height: fit-content;
    #logo-triuna {
      width: toRem(48);
      cursor: pointer;
      overflow: visible;
      padding-right: toRem(6);
      padding-top: toRem(6);
      padding-bottom: toRem(6);
      margin-top: toRem(5);
      @include laptop {
        padding: 0;
        width: toRem(50);
      }
    }
  }
  .menu-1-container {
    display: none;
    @include laptop {
      display: inline;
      grid-column: 9 / span 1;
      overflow: hidden;
      p {
        animation: reveal 0.65s ease forwards;
        animation-delay: 0.60s;
        transform: translateY(200%);
      }
    }
  }
  .menu-2-container {
    display: none;
    @include laptop {
      display: inline;
      grid-column: 11 / span 1;
      overflow: hidden;
      p {
        animation: reveal 0.65s ease forwards;
        animation-delay: 0.80s;
        transform: translateY(200%);
      }
    }
  }
  .menu-3-container {
    display: none;
    @include laptop {
      grid-column: 12 / span 2;
      display: inline;
      overflow: hidden;
      p {
        animation: reveal 0.65s ease forwards;
        animation-delay: 1s;
        transform: translateY(200%);
        span {
          display: flex;
          justify-content: flex-end;
          svg {
            align-self: center;
            margin-right: toRem(12);
          }
          .s-block {
            display: inline-block;
          }
        }
      }
    }
  }
  .menu-mobile {
    grid-column: 5 / span 1;
    margin-left: auto;
    cursor: pointer;
    height: fit-content;
    overflow: hidden;
    .menu-button {
      padding-left: toRem(6);
      padding-top: toRem(6);
      padding-bottom: toRem(6);
      animation: reveal 0.65s ease forwards;
      animation-delay: 0.65s;
      transform: translateY(200%);
    }
    @include laptop {
      display: none;
    }
  }
  // .router-link-exact-active {
  //   text-decoration: underline;
  //   text-decoration-thickness: toRem(1);
  // }
}
.lang-en {
  cursor: pointer;
}
.lang-si {
  cursor: pointer;
}
.overlay {
  background: $color-font;
  height: 100%;
  bottom: 0;
  transition: all 0.35s ease;
}
.header-mid {
  position: fixed;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @include grid;
  .mid-container {
    display: inline;
    grid-column: 2 / span 4;
    .lang {
      padding-top: toRem(24);
      .lang-select:nth-of-type(1) {
        padding: toRem(12) 0 0 0;
        margin-left: toRem(6);
        margin-right: toRem(6);
      }
      .lang-select:nth-of-type(2) {
        padding: toRem(12) toRem(12) 0 toRem(12);
      }
    }
  }
  .pb {
    padding-bottom: toRem(12);
  }
}
.header-footer {
  @include mini {
    padding-bottom: toRem(12);
  }
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: toRem(26);
  @include grid;
  .item-left {
    display: inline;
    grid-column: 2 / span 2;
  }
  .item-right {
    grid-column: 4 / span 2;
    text-align: end;
  }
}
.light {
  color: $color-bg;
}
.overlay-enter-active,
.overlay-leave-active {
  transition: all 0.35s;
}
.overlay-enter,
.overlay-leave-to
{
  opacity: 0;
}
@keyframes reveal {
  100% {
    transform: translateY(0);
  }
}
</style>
