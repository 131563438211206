import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      hover: null,
      overlay: false,
      menu: 'Menu',
      screen: 'mobile',
      projects: [
        {
          id: 13,
          name: 'wr',
          url: 'wr',
          meta: 'W*R',
          title: 'W*R — Vintage wear at its funky best',
          desc: 'Testing ways of elevating sustainable wear by following the 5R manifest: reduce, repair, recycle, repurpose & reinvent.',
          services: [
            'Visual Identity', 'UI/UX Design'
          ],
          extras: false,
          content: null,
          feature: {
            video: false,
            url: '00',
            meta: 'W*R',
            class: 'img-h-right anim-first',
            alt: 'Second hand e-commerce mobile homepage design showing video of a dressed woman on a colourful background',
            vimeo: true,
            vimeoId: '713971323',
            vimeoH: '52511cdc99',
            ratio: 'r-horizontal'
          },
          image: {
            url: '01',
            meta: 'W*R',
            class: 'img-h-center-f',
            alt: 'Second hand e-commerce mobile homepage design showing video of a dressed woman on a colourful background',
            vimeo: true,
            vimeoId: '708132440',
            vimeoH: '1df43db442',
            ratio: 'r-wide'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'W*R',
              class: 'img-h-center',
              alt: 'Vintage second hand shop homepage design with bold copy and a studio shot of a young model'
            },
            {
              url: '03',
              meta: 'W*R',
              class: 'img-v-left',
              alt: 'Logo video with changing background of different people wearing second hand clothes and emoting',
              vimeo: true,
              vimeoId: '709223872',
              vimeoH: '4112fe0109',
              ratio: 'r-vertical'
            },
            {
              url: '04',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'W*R',
              class: 'img-h-right',
              alt: 'Blue skyline shot of two women in the sun wearing vintage clothing'
            }
          ]
        },
        {
          id: 12,
          name: 'bubbl',
          url: 'bubbl',
          meta: 'Bubbl.',
          title: 'Bubbl. — The art of plant-based boba tea',
          desc: 'Drawing inspiration from tapioca pearls, Bubbl. paints its shapes the peculiar criss-cross of blue, violet and red to bring forth its bubbly identity.',
          services: [
            'Visual Identity', 'Graphic Design', 'Packaging'
          ],
          extras: false,
          content: null,
          feature: {
            url: '00',
            type: 'gif',
            mime: 'gif',
            meta: 'Bubbl',
            class: 'img-v-left anim-item',
            alt: 'Innovative violet bottle packaging for a beverage company gif',
            vimeo: false,
            vimeoId: '708956073',
            vimeoH: 'c2f46b6dfa',
            ratio: 'r-vertical'
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Bubbl',
            class: 'img-h-center-f',
            alt: 'Three different beverage bottle packaging designs on light background from above'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Bubbl',
              class: 'img-v-left',
              alt: 'Craft packaging box with white logo design closeup'
            },
            {
              url: '03',
              meta: 'Bubbl',
              type: 'jpg',
              mime: 'jpeg',
              class: 'img-h-right',
              alt: 'Bold craft packaging box design mockup with white lettering on light background'
            },
            {
              url: '04',
              meta: 'Bubbl',
              type: 'jpg',
              mime: 'jpeg',
              class: 'img-h-left',
              alt: 'White logo design with custom lettering on violet background'
            },
            {
              url: '00',
              meta: 'Bubbl',
              type: 'gif',
              mime: 'gif',
              class: 'img-h-right',
              alt: 'Innovative violet bottle packaging for a beverage company gif'
            }
          ]
        },
        {
          id: 11,
          name: 'via',
          url: 'via',
          meta: 'VIA',
          title: 'VIA — Climbing and trekking made easy',
          desc: 'Website exploration of VIA, a mountaineering design project built for the future.',
          services: [
            'Visual Identity', 'UI/UX Design'
          ],
          extras: 'Essence',
          content: 'Large scale websites don’t have to be complex. When a concept is developed through the needs of its ideal user, no amount of data will be able to impair their experience.',
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'VIA',
            class: 'img-h-center-s anim-item',
            alt: 'Alpine climbing and trekking website desktop design with black and white mountain background',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'VIA',
            class: 'img-h-center-f',
            alt: 'Alpine climbing and trekking website desktop design with black and white mountain background'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'VIA',
              class: 'img-v-left',
              alt: 'Two phones of monochrome climbing website design showing mobile overlay menu and mountain info subpage'
            },
            {
              url: '03',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'VIA',
              class: 'img-h-right',
              alt: 'Stacked phone mockups of alpine climbing and trekking mobile website black and white design'
            }
          ]
        },
        {
          id: 10,
          name: 'chevelure',
          url: 'chevelure',
          meta: 'Chevelure',
          title: 'Chevelure — Meet your new best',
          desc: 'Crafting an end to quarantine hair with Chevelure.',
          services: [
            'Visual Identity', 'UI/UX Design'
          ],
          extras: false,
          content: null,
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Chevelure',
            class: 'img-h-left anim-item',
            alt: 'Three phone mockups of hair studio website homepage design from above',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Chevelure',
            class: 'img-h-center-f',
            alt: 'Three phone mockups of hair studio website homepage design from above'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Chevelure',
              class: 'img-h-center',
              alt: 'Hair studio website desktop homepage design showing back view of a woman’s head'
            }
          ]
        },
        {
          id: 9,
          name: 'anastazija',
          url: 'i-cant-say-anything-just-like-that',
          meta: 'I can’t say anything just like that',
          title: 'I can’t say anything just like that',
          desc: '<span class="italics">I can’t say anything just like that</span> exhibition catalogue that aims to extend the ideas running throughout Anastazija Pirnat’s work in order to emphasise her conceptual affinities.',
          services: [
            'Graphic Design', 'Publication'
          ],
          extras: 'Collaborators',
          content: [
            'Anastazija Pirnat', 'Neja Kaiser'
          ],
          feature: {
            url: '00',
            type: 'gif',
            mime: 'gif',
            meta: 'Anastazija Pirnat',
            class: 'img-h-right-s anim-item',
            alt: 'KGLU exhibition catalogue changing pages showing artwork with descriptions from above',
            vimeo: false,
            vimeoId: '708991843',
            vimeoH: 'f686f91e19',
            ratio: 'r-horizontal'
          },
          image: {
            url: '01',
            type: 'gif',
            mime: 'gif',
            meta: 'Anastazija Pirnat',
            class: 'img-h-center-f',
            alt: 'KGLU exhibition catalogue changing pages showing artwork with descriptions from above'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Anastazija Pirnat',
              class: 'img-v-left',
              alt: 'Stack of thin white artist bilingual publications on black background'
            },
            {
              url: '03',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Anastazija Pirnat',
              class: 'img-h-right',
              alt: 'KGLU art exhibition bilingual catalogue cover and opening page from above'
            }
          ]
        },
        {
          id: 8,
          name: 'logofolio',
          url: 'logofolio',
          meta: 'Logofolio',
          title: 'Logofolio',
          desc: 'Selection of visuals giving heart to every brand, business or upcoming project — the logotypes.',
          services: [
            'Graphic Design', 'Visual Identity'
          ],
          extras: false,
          content: null,
          feature: {
            url: '00',
            type: 'gif',
            mime: 'gif',
            meta: 'Logotypes',
            class: 'img-h-center-s anim-item',
            alt: 'White minimalistic logo designs on black background gif',
            vimeo: false,
            vimeoId: '708956046',
            vimeoH: 'a8892fe3c6',
            ratio: 'r-horizontal'
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Logotypes',
            class: 'img-h-center-f',
            alt: 'Hugo wolf black musical line art logo design on light background'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Logotypes',
              class: 'img-h-center',
              alt: 'Black minimalistic line art eye logo design for a moodboard company'
            },
            {
              url: '03',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Logotypes',
              class: 'img-h-center',
              alt: 'Black brutalist logo design with bold lettering on light background'
            },
            {
              url: '04',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Logotypes',
              class: 'img-h-center',
              alt: 'Strong black minimalistic logo lettering design for a non-profit'
            },
            {
              url: '05',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Logotypes',
              class: 'img-h-center',
              alt: 'Playful black logo design with wolf symbol holding a flag'
            },
            {
              url: '07',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Logotypes',
              class: 'img-h-center',
              alt: 'Elegant minimalistic black typography logotype design on light background'
            },
            {
              url: '06',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Logotypes',
              class: 'img-h-center',
              alt: 'Elegant minimalistic black lettering logo design on light background'
            }
          ]
        },
        {
          id: 7,
          name: 'take',
          url: 'take-two',
          meta: 'Take Two Posters',
          title: 'Take Two Posters',
          desc: 'An exploration of the power of rebranding in collaboration with Take Two Posters.',
          services: [
            'Art Direction', 'Graphic Design', 'Film and Motion'
          ],
          extras: 'Essence',
          content: 'A reimagination of vintage marketing efforts revitalised for the 21st century.',
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Take Two',
            class: 'img-v-right anim-item',
            alt: 'Large funny pink poster for home supplies street view',
            vimeo: false,
            vimeoId: '708955956',
            vimeoH: '342a91d036',
            ratio: 'r-vertical'
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Take Two',
            class: 'img-h-center-f',
            alt: 'Large funny pink poster for home supplies street view'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Take Two',
              class: 'img-h-center',
              alt: 'Vintage marketing ad for home supplies on black background'
            },
            {
              url: '03',
              type: 'gif',
              mime: 'gif',
              meta: 'Take Two',
              class: 'img-v-left',
              alt: 'Pink and yellow home supply posters gif'
            },
            {
              url: '04',
              type: 'gif',
              mime: 'gif',
              meta: 'Take Two',
              class: 'img-v-right',
              alt: 'Funny colourful posters for home supplies gif'
            }
          ]
        },
        {
          id: 6,
          name: 'roadhub',
          url: 'roadhub',
          meta: 'RoadHub',
          title: 'RoadHub — Shaping the future of traffic',
          desc: 'Wondering how we can evolve the look and feel of cities worldwide? You needn’t search farther than RoadHub.',
          services: [
            'Web Design', 'Visual Identity', 'Film and Motion'
          ],
          extras: 'Essence',
          content: 'At once innovative, economical and eco-friendly, the developers behind the idea are pushing the boundaries of interchange systems.',
          feature: {
            url: '00',
            meta: 'Roadhub',
            class: 'img-h-right anim-item',
            alt: 'RoadHub engineering website homepage video of city skyline',
            vimeo: true,
            // vimeoId: '708955987',
            // vimeoH: '05bf8819ed',
            vimeoId: '708956004',
            vimeoH: '9449e0780c',
            ratio: 'r-wide'
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Roadhub',
            class: 'img-h-center-f',
            alt: 'Stacked phones showing RoadHub engineering website on blue background'
          },
          images: [
            {
              url: '02',
              meta: 'Roadhub',
              class: 'img-h-center',
              alt: 'RoadHub engineering website homepage video of city skyline',
              vimeo: true,
              vimeoId: '708956004',
              vimeoH: '9449e0780c',
              ratio: 'r-wide'
            },
            {
              url: '03',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Roadhub',
              class: 'img-v-left',
              alt: 'Two vertical phones of RoadHub interchange systems website'
            },
            {
              url: '04',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Roadhub',
              class: 'img-h-right',
              alt: 'Slanted computer screen on a desk showing RoadHub tech website homepage video'
            },
            {
              url: '05',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Roadhub',
              class: 'img-h-center',
              alt: 'Desk view of laptop and phone showing RoadHub engineering website contact page and mobile overlay menu'
            }
          ]
        },
        {
          id: 5,
          name: 'plaktivat',
          url: 'plaktivat-coronavirus',
          meta: 'Plaktivat: Coronavirus',
          title: 'Plaktivat: Coronavirus',
          desc: 'How have you been dealing with the ongoing crisis? Wary of meat industry’s next calamity, we called for systemic change as the only longterm solution.',
          services: [
            'Art Direction', 'Graphic Design'
          ],
          extras: 'Essence',
          content: 'Part of TAM-TAM’s open call which saw a collection of over 200 posters raising Covid-19 awareness on the streets of Slovenia.',
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Plaktivat: Coronavirus',
            class: 'img-v-left anim-item',
            alt: 'Person holding large coronavirus environmental awareness poster in front of the sea',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Plaktivat: Coronavirus',
            class: 'img-h-center-f',
            alt: 'Person holding large coronavirus environmental awareness poster in front of the sea'
          }
        },
        {
          id: 4,
          name: 'suzana',
          url: 'suzana-pecoler',
          meta: 'Šepetalka kristalov',
          title: 'Šepetalka kristalov',
          desc: 'There’s jewellery and there’s jewellery with a purpose. Suzana Pečoler’s mesmerising handiwork is one of the latter.',
          services: [
            'Art Direction', 'Photography'
          ],
          extras: false,
          content: null,
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Suzana Pečoler',
            class: 'img-h-center-s anim-item',
            alt: 'Innovative minimalistic phone mockup of handmade jewellery with natural leaves',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Suzana Pečoler',
            class: 'img-h-center-f',
            alt: 'Innovative minimalistic phone mockup of handmade jewellery with natural leaves'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Suzana Pečoler',
              class: 'img-v-left',
              alt: 'Closeup product shot of woman’s ear wearing handmade woven jewellery with crystal'
            },
            {
              url: '03',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Suzana Pečoler',
              class: 'img-v-right',
              alt: 'Minimalist product shot of handmade woven jewellery with green crystals on white background'
            }
          ]
        },
        {
          id: 3,
          name: 'plantiosa',
          url: 'plantiosa',
          meta: 'Plantiosa',
          title: 'Plantiosa — Plant-based is the new black',
          desc: 'We partnered with Plantiosa in the hope of putting a breath of fresh air into the food blogging sphere.',
          services: [
            'Visual Identity', 'Art Direction', 'Photography', 'Film and Motion', 'Website Design'
          ],
          extras: 'Essence',
          content: 'Opting for an intersection of bold fonts and fresh colours, we chose a minimalistic approach while maximising meaning.',
          feature: {
            url: '00',
            type: 'gif',
            mime: 'gif',
            meta: 'Plantiosa',
            class: 'img-h-left anim-item',
            alt: 'Colourful plant-based foodblog minimalistic website design gif',
            vimeo: false,
            vimeoId: '708956022',
            vimeoH: '59b43891e8',
            ratio: 'r-horizontal'
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Plantiosa',
            class: 'img-h-center-f',
            alt: 'White plant-based foodblog logo design on black background'
          },
          images: [
            {
              url: '02',
              type: 'gif',
              mime: 'gif',
              meta: 'Plantiosa',
              class: 'img-h-center',
              alt: 'Colourful plant-based foodblog minimalistic website design gif'
            },
            {
              url: '03',
              type: 'gif',
              mime: 'gif',
              meta: 'Plantiosa',
              class: 'img-h-center',
              alt: 'Colourful plant-based food photography from above gif',
              vimeo: false,
              vimeoId: '708539835',
              vimeoH: '7d103cc2df',
              ratio: 'r-wide'
            },
            {
              url: '04',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Plantiosa',
              class: 'img-h-center',
              alt: 'Two white phones showing plant-based foodblog minimalistic mobile design on yellow background'
            }
          ]
        },
        {
          id: 2,
          name: 'forma',
          url: 'forma-neue',
          meta: 'Forma Neue',
          title: 'Forma Neue',
          desc: 'Concept development for an artisan store focused on fine art objects made from recycled materials.',
          services: [
            'Visual Identity', 'UI/UX Design'
          ],
          extras: false,
          content: null,
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Forma Neue',
            class: 'img-h-center-s anim-item',
            alt: 'Desktop homepage view of artisan bilingual e-commerce store design for fine art objects',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Forma Neue',
            class: 'img-h-center-f',
            alt: 'Desktop homepage view of artisan bilingual e-commerce store design for fine art objects'
          }
        },
        {
          id: 1,
          name: 'bookclub',
          url: 'bookclub',
          meta: 'Bookclub by Triuna',
          title: 'Bookclub by Triuna',
          desc: 'Can we transform the way we retain information?',
          services: [
            'Digital Design', 'Art Direction', 'Photography'
          ],
          extras: 'Essence',
          content: 'Spirited creativity unhinged by plenty of questions was positioned at the centre of our quest: find the heart of each new book and cement it in the depositories of long-term knowledge.',
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Bookclub by Triuna',
            class: 'img-v-right anim-item',
            alt: 'Sunset colored background with modern Bookclub logo',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Bookclub by Triuna',
            class: 'img-h-center-f',
            alt: 'Sunset colored background with modern Bookclub logo'
          }
        },
        {
          id: 0,
          name: 'prevent',
          url: 'prevent-deloza',
          meta: 'Prevent & Deloza',
          title: 'Prevent & Deloza — FireCat Ecotact',
          desc: 'Known for their innovative materials and unparalleled style, Prevent & Deloza has been a steady force at the forefront of smart workwear.',
          services: [
            'Visual Identity', 'Photography', 'Art Direction'
          ],
          extras: 'Essence',
          content: 'We had the pleasure of documenting the creation of their newest line of eco-friendly workwear: FireCat Ecotact. Working within the renowned visual structure of the existing brand while simultaneously refreshing its core allowed us to pay the line’s inspiring story its visual due.',
          feature: {
            url: '00',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Prevent & Deloza FireCat Ecotact',
            class: 'img-h-right anim-item',
            alt: 'Sunset shot of young man wearing innovative green workwear design',
            vimeo: false
          },
          image: {
            url: '01',
            type: 'jpg',
            mime: 'jpeg',
            meta: 'Prevent & Deloza FireCat Ecotact',
            class: 'img-h-center-f',
            alt: 'Sunset shot of young man wearing innovative green workwear design'
          },
          images: [
            {
              url: '02',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Prevent & Deloza FireCat Ecotact',
              class: 'img-h-center',
              alt: 'Moody forest shot showing foggy treetops'
            },
            {
              url: '03',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Prevent & Deloza FireCat Ecotact',
              class: 'img-v-left',
              alt: 'Closeup product shot of innovative green workwear rain jacket design'
            },
            {
              url: '04',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Prevent & Deloza FireCat Ecotact',
              class: 'img-h-right',
              alt: 'Closeup product back shot of innovative green workwear rain jacket with hood design'
            },
            {
              url: '05',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Prevent & Deloza FireCat Ecotact',
              class: 'img-h-center',
              alt: 'Golden hour sunrise forest with low clouds shot from above'
            },
            {
              url: '06',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Prevent & Deloza FireCat Ecotact',
              class: 'img-h-left',
              alt: 'Young forest worker with binoculars wearing modern green workwear jacket design'
            },
            {
              url: '07',
              type: 'jpg',
              mime: 'jpeg',
              meta: 'Prevent & Deloza FireCat Ecotact',
              class: 'img-v-right',
              alt: 'Golden hour shot of a forest worker wearing modern green workwear jacket and pants from behind'
            }
          ]
        }
      ]
    }
  },
  getters: {
    projects (state) {
      return state.projects
    },
    hover (state) {
      return state.hover
    },
    overlay (state) {
      return state.overlay
    },
    screen (state) {
      return state.screen
    },
    menu (state) {
      return state.menu
    }
  },
  mutations: {
    hover (state, payload) {
      state.hover = payload
    },
    overlay (state, payload) {
      state.overlay = payload
    },
    setScreen (state, payload) {
      state.screen = payload
    },
    menu (state, payload) {
      state.menu = payload
    }
  },
  actions: {
    hover (context, payload) {
      context.commit('hover', payload)
    },
    overlay (context, payload) {
      context.commit('overlay', payload)
      if (payload === true) {
        context.commit('menu', 'Close')
        document.body.classList.add('overlay-open')
      } else if (payload === false) {
        context.commit('menu', 'Menu')
        document.body.classList.remove('overlay-open')
      }
    },
    setScreen (context, payload) {
      context.commit('setScreen', payload)
      if (payload === 'laptop') {
        context.commit('overlay', false)
        document.body.classList.remove('overlay-open')
        context.commit('menu', 'Menu')
      }
    }
  },
  modules: {
  }
})
