<template>
  <section>
    <h1 class="h1">
      <span class="line-container anim-block">
        <span class="line line-one anim">We make the world go</span>
      </span>
      <br class="break-m">
      <span class="line-container container-2 anim-block">
        <span class="line line-two anim delay-1"><span class="text"></span>&nbsp;over you</span>
      </span>
    </h1>
  </section>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue'
// import { useStore } from 'vuex'
import { gsap } from 'gsap'
import { RoughEase } from 'gsap/EasePack'
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin, RoughEase)

export default {
  setup () {
    // const store = useStore()
    // const screen = computed(() => store.getters.screen)
    const words = ref(['wild', 'mushy', 'pining', 'wooooow', 'gushing', 'bananas'])
    // const index = ref(0)
    // const word = ref(words.value[0])
    // setInterval(function () {
    //   word.value = words.value[index.value++ % words.value.length]
    // }, 1000)

    const timelineStart = gsap.timeline({})
    const timeline = gsap.timeline({ repeat: -1 })
    onMounted(() => {
      // if (screen.value === 'laptop') {
      //   gsap.from('.line', { y: '100%', duration: 2, ease: 'Expo.easeOut', stagger: 0.35 })
      // }
      timeline.pause()
      const tlStart = gsap.from('.text', {
        duration: 0.75,
        text: 'bananas',
        delay: 1.5,
        onComplete () {
          timeline.resume()
        }
      })
      timelineStart.add(tlStart)
      words.value.forEach(word => {
        const tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 0.75 })
        tl.to('.text', { duration: 0.75, text: word })
        timeline.add(tl)
      })
    })
    onBeforeUnmount(() => {
      timeline.kill()
      timelineStart.kill()
      gsap.to('.text', { text: '' })
    })
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  // min-height: 61vh:
  @include grid;
  @include laptop {
    // min-height: 78.89vh;
    min-height: 74vh;
  }
  h1 {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    grid-column: 2 / span 4;
    padding-top: toRem(220);
    @include laptop {
      padding-top: toRem(320);
      display: block;
      grid-column: 2 / span 12;
    }
    .line-container {
      display: inline-block;
      overflow: hidden;
      .line {
        display: inline-block;
      }
      .line-one {
        padding-bottom: toRem(18);
        @include laptop {
          padding-bottom: toRem(10);
          padding-right: toRem(6);
        }
      }
      .line-two {
        padding-bottom: toRem(12);
        @include laptop {
          position: relative;
          bottom: toRem(22);
          padding-top: toRem(14);
          padding-bottom: 0;
          display: inline-block;
        }
      }
    }
    .container-2 {
      position: relative;
      bottom: toRem(14);
      @include laptop {
        bottom: toRem(16);
      }
    }
    .break-m {
      display: none;
      @include laptop {
        display: block;
      }
    }
  }
}
// .anim-block {
//   animation: animBlock 0.65s ease forwards;
//   transform: translate3d(0, 100%, 0);
//   @keyframes animBlock {
//     100% {
//       transform: translate3d(0, 0, 0);
//     }
//   }
// }
.anim {
animation: anim 0.65s ease forwards;
transform: translate3d(0, 100%, 0);
}
.delay-1 {
  animation-delay: 0.25s;
}
@keyframes anim {
  100% {
    transform: translate3d(0, 0, 0);
  }
}
</style>
